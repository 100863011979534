@import "../../../../styles/colors";
@import "../../../../styles/variables";

.contact__bottom {
  height: auto;
  display: flex;
  width: auto;
  justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.048);
  &__left {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin-left: 140px;
  }
  img {
    width: 600px;
    margin-bottom: 10px;
  }
  &__medias {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__media {
    @include button($red, white, 30px);
    line-height: 10px;
    margin-top: 10px;
    padding: 10px;
    width: 150px;
    font-size: 16px;
    &:hover {
      background-color: $red-hover;
      transform: scale(1.05);
    }
    &--link {
      text-decoration: none;
      color: white;
    }
  }
  .form__div {
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.103);
    padding-top: 40px;
    margin-right: 160px;
  }
  h1 {
    font-family: inter-bold;
    color: $title-color;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 1400px) {
  .contact__bottom {
    flex-direction: column;
    align-items: center;
    &__left {
      display: none;
    }
    .form__div {
      margin: 0;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 900px) {
  .contact__bottom h1 {
    font-size: 30px;
  }
}

@media (max-width: 440px) {
  .form__div {
    width: 100%;
    padding-inline: 0;
  }
}
