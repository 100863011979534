@import "../../styles/colors";

.footer {
  height: auto;
  &__top {
    height: 220px;
    background-color: $footer-color;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .left,
    .right {
      text-align: center;
      color: white;
      position: relative;
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 12px;
    font-family: "Poppins", sans-serif;
    border-bottom: 1px solid rgba(255, 255, 255, 0.295);
    width: 135px;
    margin: 12px auto;
    margin-top: 0;
    align-items: center;
  }
  legend {
    text-align: center;
    & a {
      font-size: 21px;
      text-decoration: none;
      transition: 200ms all;
      color: white;
      font-family: os-light;
      cursor: pointer;
      &:hover {
        color: $red-hover;
      }
    }
  }
  img {
    width: 280px;
    cursor: pointer;
  }
  &__copy {
    width: 100%;
    background-color: $footer-color;
    color: white;
    font-family: os-light;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.295);
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

@media (max-width: 1400px) {
  .footer {
    img {
      width: 220px;
    }
  }
}

@media (max-width: 900px) {
  .footer {
    img {
      width: 180px;
    }
  }
}

@media (max-width: 650px) {
  .footer {
    &__img--div {
      display: none;
    }
  }
}

@media (max-width: 440px) {
  .footer {
    h3 {
      font-size: 20px;
    }
    legend {
      font-size: 18px;
    }
  }
}
