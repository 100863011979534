@import "../../../../styles/colors";

.card {
  box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.103);
  padding: 15px;
  max-width: 300px;
  border-radius: 15px;
  height: 270px;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
  &:hover {
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.15),
      0 2px 4px -1px rgba(0, 0, 0, 0.09);
  }
  &__title {
    font-family: cocogoose;
    margin: 10px 0;
  }

  &__text {
    font-family: os-light;
  }

  &__img {
    width: 60px;
    margin-left: 10px;
  }
}

.knowmore {
  width: 100%;
  &__div {
    width: 88px;
    opacity: 0;
    transform: translateX(0) scale(0);
    transition: 0.5s all;
    &--mobile {
      display: none;
    }
  }
  &__link {
    font-family: cocogoose;
    font-size: 14px;
    color: $blue;
    text-decoration: none;
    transition: 0.3s all;
    cursor: pointer;
    &:hover,
    &--mobile:hover {
      color: $dark-blue;
    }
  }
  &__active {
    opacity: 1;
  }
}

@media (max-width: 1580px) {
  .card {
    height: 284px;
  }
}

@media (max-width: 1400px) {
  .card {
    max-width: 100%;
  }
}

@media (max-width: 900px) {
  .card__text {
    font-size: 15px;
  }
  .knowmore {
    display: none;
    &__link--mobile {
      color: $blue;
      text-decoration: none;
    }
    &__div--mobile {
      display: block;
      text-align: center;
      font-family: cocogoose;
      font-size: 14px;
      text-decoration: none;
      transition: 0.3s all;
      cursor: pointer;
    }
  }
}
