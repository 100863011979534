@import "../../../styles/colors";

.banks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.048);
  padding-bottom: 40px;
  height: auto;
  &__carousel {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
  }
  h1 {
    color: $title-color;
    text-transform: uppercase;
    font-family: inter-bold;
    font-size: 35px;
    margin-bottom: 50px;
  }
  img {
    width: 150px !important;
  }
}

@media (max-width: 1400px) {
  .banks__carousel {
    width: 98%;
  }
}

@media (max-width: 900px) {
  .banks h1 {
    font-size: 30px;
  }
  .banks img {
    width: 120px !important;
  }
}

@media (max-width: 900px) {
  .banks img {
    width: 110px !important;
  }
}
@media (max-width: 440px) {
  .banks {
    h1 {
      font-size: 26px;
    }
  }
}
