* {
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
}
@font-face {
    font-family: montserrat;
    src: url('/src/assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: cocogoose;
    src: url('/src/assets/fonts/Cocogoose.ttf') format('truetype');
}
@font-face {
    font-family: os-regular;
    src: url('/src/assets/fonts/OpenSans-Regular.ttf');
}
@font-face {
    font-family: inter-bold;
    src: url('/src/assets/fonts/Inter-Bold.ttf');
}
@font-face {
    font-family: os-light;
    src: url('/src/assets/fonts/OpenSans-Light.ttf');
}
@font-face {
    font-family: os-italic;
    src: url('/src/assets/fonts/OpenSans-Italic.ttf');
}
@font-face {
    font-family: heycomic;
    src: url('/src/assets/fonts/Hey-Comic.ttf');
}
