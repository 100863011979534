@import "../../../../../styles/colors";
@import "../../../../../styles/variables";

.form {
  display: flex;
  flex-direction: column;
  padding: 22px 22px 8px 22px;
  width: 480px;
  label {
    font-family: cocogoose;
    font-size: 16px;
    margin-bottom: 5px;
  }
  InputMask,
  input,
  textarea {
    font-family: os-regular;
    margin-bottom: 10px;
    padding: 8px 10px;
    border-radius: 5px;
    outline: 0;
    border: 1px solid $title-color;
    font-size: 17px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.575);
      font-size: 17px;
      font-family: os-regular;
    }
  }
  &__flex {
    display: flex;
    div {
      display: flex;
      flex-direction: column;
      width: 49%;
      &:not(:first-child) {
        margin-left: 2%;
      }
    }
  }
  textarea {
    resize: none;
  }
  button {
    @include button($red, white, 30px);
    font-size: 20px;
    padding: 0px;
    width: 40%;
    height: 30px;
    margin: 20px auto 0 auto;
    &:hover {
      background-color: $red-hover;
      transform: scale(1.05);
    }
  }
}

.input-error {
  border: $red 1.5px solid !important;
  animation: error 0.3s;
}

@keyframes error {
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(3px);
  }
}

@media (max-width: 650px) {
  .form {
    padding: 10px;
    width: 390px;
  }
}
@media (max-width: 440px) {
  .form {
    width: 300px;
    padding: 5px;
  }
}
