@import "../../../../styles/colors";

.faq__box {
  width: 800px;
  font-family: inter-bold;
  letter-spacing: 1.1px;
  color: $subtitle-color;
  font-size: 24px;
  padding: 10px 10px 10px 0;
  margin-left: 5px;

  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
}

.faq__button {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  font-size: 1.5rem;
  padding-inline-start: 1rem;

  -webkit-padding-end: 1rem;
  padding-inline-end: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: none;
  background-color: white;
  &:hover {
    background-color: $light-gray;
  }
}

.faq__answer {
  font-family: montserrat;
  font-size: 17px;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 15px !important;
}

.faq__item {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.11);
}

@media (max-width: 1400px) {
  .faq__box {
    font-size: 19px !important;
  }
  .faq__answer {
    font-size: 16px !important;
  }
}

@media (max-width: 650px) {
  .faq__answer {
    font-size: 15px !important;
  }
}
