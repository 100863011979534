@import "../../../styles/colors";
@import "../../../styles/variables";

.service {
  display: flex;
  padding-top: 100px;
  padding-bottom: 60px;
  height: auto;
  position: relative;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    height: auto;
  }
  &__info {
    width: 40%;
    margin-right: 400px;
    position: relative;
  }
  .backpage {
    &__div {
      width: 45px;
      height: 45px;
      top: -60px;
      left: -60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      cursor: pointer;
      transition: 0.2s all;
      color: $blue;
      &:hover {
        transform: scale(1.1);
      }
    }
    &__arrow {
      font-size: 35px;
    }
  }
  h1 {
    font-family: inter-bold;
    font-size: 30px;
    margin-bottom: 50px;
  }
  .text {
    font-family: montserrat;
    font-weight: 400;
    text-indent: 20px;
    font-size: 18px;
    line-height: 2rem;
  }
  .right {
    height: auto;
    position: absolute;
    right: 100px;
    top: 100px;
    padding: 10px 0 10px 50px;
    h3 {
      font-family: cocogoose;
      margin-bottom: 20px;
      font-size: 15px;
      font-weight: 100;
      display: block;
    }
    &__services {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &__button,
  &__button--mobile {
    @include button($red, white, 5px);
    font-size: 20px;
    width: 250px;
    &:hover {
      background-color: $red-hover;
      transform: scale(1.08);
    }
  }
  &__button--mobile {
    display: none;
  }
}

@media (max-width: 900px) {
  .service {
    flex-direction: column;
    h1 {
      font-size: 26px;
    }
    &__info {
      margin: 0;
      width: 90%;
    }
    .backpage {
      &__div {
        left: inherit;
        width: 35px;
        height: 35px;
      }
      &__arrow {
        font-size: 20px;
      }
    }
    .right {
      position: inherit;
      top: inherit;
      right: inherit;
      padding: 0 30px;
      &__services {
        display: grid;
        grid-template-columns: 32% 32% 32%;
      }
    }
    &__button {
      display: none;
      &--mobile {
        display: flex;
        margin-bottom: 50px;
      }
    }
  }
}

@media (max-width: 660px) {
  .service {
    .right {
      &__services {
        grid-template-columns: 50% 50%;
        height: auto;
      }
    }
  }
}
@media (max-width: 400px) {
  .service {
    .right {
      &__services {
        grid-template-columns: 100%;
      }
    }
  }
}
