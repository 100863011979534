@import "../../../styles/colors";

.services {
  height: auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: auto;
  h1 {
    font-family: cocogoose;
    font-weight: bold;
    font-size: 50px;
    margin-top: 30px;
  }
  h2 {
    font-size: 18px;
    margin-top: 5px;
    font-family: os-regular;
    font-weight: 100;
  }
  &__cards {
    margin-top: 50px;
    display: grid;
    width: 70%;
    grid-template-columns: repeat(3, 300px);
    column-gap: 60px;
    row-gap: 40px;
    text-align: center;
    place-content: center;
    padding-bottom: 50px;
    place-items: center;
  }
}

@media (max-width: 1200px) {
  .services {
    &__cards {
      column-gap: 30px;
      row-gap: 20px;
      width: 100%;
      grid-template-columns: repeat(3, 252px);
    }
  }
}

@media (max-width: 900px) {
  .services {
    h1 {
      font-size: 35px;
    }
    h2 {
      font-size: 16px;
    }
    &__cards {
      grid-template-columns: repeat(2, 50%);
      width: 80%;
    }
  }
}

@media (max-width: 600px) {
  .services {
    &__cards {
      grid-template-columns: 100%;
      width: 70%;
    }
  }
}
@media (max-width: 450px) {
  .services {
    h1 {
      font-size: 27px;
    }
    h2 {
      font-size: 14px;
    }
    &__cards {
      grid-template-columns: 100%;
      width: 90%;
    }
  }
}
