@import "../../../styles/colors";
@import "../../../styles/variables";

.banner {
  a {
    text-decoration: none;
  }
  height: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid rgba(0, 0, 0, 0.048);
  &__title {
    font-family: inter-bold;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: $title-color;
    &--mobile {
      display: none;
    }
  }
  &__info {
    margin-left: 150px;
  }
  p {
    margin-top: 20px;
    font-size: 22px;
    font-family: os-regular;
  }
  &__buttons {
    display: flex;
    align-items: center;
    & a:first-child div {
      @include button($red, white, 5px);
      font-size: 18px;
      &:hover {
        background-color: $red-hover;
        transform: scale(1.08);
      }
    }
    & a:last-child div {
      @include button(white, $red, 5px);
      margin-left: 15px;
      &:hover {
        color: white;
        background-color: $red-hover;
      }
    }
  }
  img {
    width: 600px;
    height: 600px;
    margin-right: 50px;
  }
}

@media (max-width: 1400px) {
  .banner {
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    &__info {
      margin: 0;
      text-align: center;
      order: 3;
    }
    img {
      order: 2;
      margin: 0;
    }
    &__title {
      display: none;
      &--mobile {
        display: block;
        font-family: inter-bold;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: $title-color;
      }
    }
    &__buttons {
      justify-content: center;
    }
  }
}

@media (max-width: 900px) {
  .banner {
    &__title--mobile {
      font-size: 35px;
      line-height: 35px;
      margin-top: 20px;
      line-height: 30px;
    }
    img {
      width: 350px;
      height: auto;
    }
    p {
      font-size: 18px;
      margin-left: 7px;
      margin-right: 7px;
    }
  }
}

@media (max-width: 650px) {
  .banner__buttons {
    flex-direction: column;
    justify-content: center;
  }
  .banner__buttons a:last-child div {
    margin-top: 10px;
    margin-left: 0;
  }
}

@media (max-width: 440px) {
  .banner {
    img {
      width: 99%;
    }
    &__title--mobile {
      font-size: 26px;
    }
  }
}