@import "../../styles/colors";

.header {
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: 160px;
  background-color: white;
  line-height: 10px;

  &__logo {
    width: 170px;
    height: 45px;
    margin-top: 10px;
    cursor: pointer;
  }
  &__ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    & a {
      text-decoration: none;
      color: black;
      position: relative;
      letter-spacing: 0.5px;
      cursor: pointer;
      transition: 0.4s all;
      &:hover {
        color: $red;
      }
    }
    & a:nth-child(-n + 4)::after {
      content: "";
      position: absolute;
      background-color: $red;
      height: 2px;
      width: 0;
      left: 0;
      bottom: -10px;
      transition: 350ms all;
    }
    & a:hover:after {
      width: 100%;
    }
  }
  li {
    padding: 10px;
    text-transform: uppercase;
    font-size: 22px;
    font-family: montserrat;
    transition: 400ms all;
  }
  #li__contato {
    color: white;
    background-color: $red;
    border-radius: 30px;
    padding: 20px 20px;
    margin-left: 10px;
    transition: 300ms all ease;
    &:hover {
      color: white;
      background-color: $red-hover;
      transform: scale(1.05);
    }
    & a {
      color: white;
    }
  }
  .mobile__menu {
    display: none;
  }
  &__nav.active {
    transform: translateX(0);
  }
}

@media (max-width: 900px) {
  @keyframes navLinkFade {
    from {
      transform: translateX(10px);
    }
    to {
      transform: translateX(0);
    }
  }
  .header {
    height: 60px;
    justify-content: space-between;
    &__logo {
      margin-left: 20px;
    }
    &__nav {
      top: 0;
      right: 0;
      width: 58vw;
      height: 100vh;
      background-color: white;
      transform: translateX(100%);
      transition: transform 300ms ease-in;
      position: fixed;
      z-index: 2;
      border-left: 1px solid rgba(0, 0, 0, 0.363);
    }
    .mobile__menu {
      display: block;
      cursor: pointer;
      z-index: 3;
      margin-right: 30px;
      position: fixed;
      right: 0;
      & div {
        width: 32px;
        height: 3px;
        margin: 8px;
        background-color: $dark-blue;
        transition: 0.4s all ease;
        border-radius: 100px;
      }
      &.active .line1 {
        transform: rotate(-45deg) translate(-8px, 8px);
      }
      &.active .line2 {
        opacity: 0;
      }
      &.active .line3 {
        transform: rotate(45deg) translate(-7px, -8px);
      }
    }
    #li__contato {
      color: black;
      background-color: transparent;
      border-radius: 0px;
      padding: 10px;
      margin-left: 0;
      transition: 300ms all ease;
      &:hover {
        color: $dark-blue;
        background-color: transparent;
        transform: scale(1);
      }
    }
    .header__ul {
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      & li {
        transform: translateX(10px);
        margin: 0;
        & a:hover {
          color: $dark-blue;
        }
        & a:nth-child(-n + 4)::after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .header__logo {
    margin-left: 20px;
    width: 150px;
    height: 38px;
    margin-top: 1px;
    border-radius: 2px;
  }
}
