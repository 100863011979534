@import "../../../styles/colors";

.about {
  display: flex;
  justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.048);
  height: auto;
  h1 {
    font-family: inter-bold;
    color: $title-color;
    font-size: 40px;
    text-align: left;
  }
  div {
    width: 500px;
    margin-left: 100px;
  }
  img {
    width: 450px;
    margin-right: 50px;
    padding-bottom: 50px;
    margin-top: 15px;
  }
  p {
    font-family: montserrat;
    margin-top: 30px;
    line-height: 2rem;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.527);
    text-align: justify;
  }
  .bold {
    font-weight: bold;
  }
  .underline {
    text-decoration: underline;
  }
}

@media (max-width: 1400px) {
  .about {
    flex-direction: column;
    align-items: center;
    h1 {
      text-align: center;
    }
    img {
      order: 1;
      width: inherit;
      max-width: 450px;
      margin: 0;
      padding: 0;
    }
    div {
      margin-left: 0;
      width: 60%;
      order: 2;
    }
  }
}

@media (max-width: 900px) {
  .about {
    img {
      display: none;
    }
    h1 {
      font-size: 30px;
    }
    div {
      margin-top: 50px;
      width: 100%;
    }
    p {
      font-size: 16px;
      margin: 20px 10px;
      text-align: justify;
    }
  }
}
