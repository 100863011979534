@import "../../../styles/colors";

.faq {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
  h1 {
    color: $title-color;
    text-transform: uppercase;
    font-family: inter-bold;
    font-size: 35px;
    margin-bottom: 40px;
  }
  &__accordion {
    width: 800px;
  }
}

@media (max-width: 1400px) {
  .faq {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 60px;
    h1 {
      font-size: 30px;
    }
    &__accordion {
      width: 600px;
    }
  }
}
@media (max-width: 900px) {
  .faq__accordion {
    width: 80%;
  }
}

@media (max-width: 440px) {
  .faq {
    h1 {
      font-size: 26px;
    }
    &__accordion {
      width: 100%;
    }
  }
}
