@mixin button($background-color, $text-color, $radius) {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-family: cocogoose;
  text-transform: uppercase;
  padding: 12px 15px;
  transition: 300ms all ease-in-out;
  margin-top: 30px;
  font-size: 15px;
  border: 1px solid $red;
  background-color: $background-color;
  color: $text-color;
  border-radius: $radius;
}
