$page-selection: rgb(165, 194, 247);

$red: #FF0000;
$red-hover: #eb4d4b;
$dark-blue: #0007A7;
$blue: #264BE5;

$title-color: #050336;
$subtitle-color: #040246;

$light-gray: #EDEDED;
$dark-gray: #414141;

$footer-color: #6b1d26;
*::selection {
    background-color: $page-selection;
}


