@import "../../../../styles/colors";

.contact__top {
  height: auto;
  display: flex;
  justify-content: space-around;
  padding-top: 40px;
  align-items: center;
  &__left {
    margin-left: 150px;
    h1 {
      font-family: inter-bold;
      color: $title-color;
      font-size: 40px;
      margin-bottom: 50px;
    }
  }
  &__address {
    font-family: os-italic;
    margin-top: 15px;
    color: rgba(0, 0, 0, 0.808);
    &::before {
      background-image: url("../../../../assets/images/contact/marker.gif");
      background-size: 15px 15px;
      display: inline-block;
      width: 15px;
      height: 15px;
      content: "";
      margin-right: 8px;
    }
    & a {
      color: rgba(0, 0, 0, 0.808);
    }
  }
  &__phone {
    font-family: os-regular;
    margin-top: 3px;
    &::before {
      background-image: url("../../../../assets/images/contact/phone.gif");
      background-size: 15px 15px;
      display: inline-block;
      width: 15px;
      height: 15px;
      content: "";
      margin-right: 6px;
    }
    & a {
      color: rgba(0, 0, 0, 0.808);
    }
  }
  h2 {
    font-family: cocogoose;
    font-weight: bold;
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 5px;
  }
  legend {
    font-family: os-regular;
    color: rgba(0, 0, 0, 0.808);
    &::before {
      background-image: url("../../../../assets/images/contact/watch.gif");
      background-size: 13px 13px;
      display: inline-block;
      width: 13px;
      height: 13px;
      content: "";
      margin-right: 8px;
    }
  }
  &__right {
    margin-right: 100px;
    img {
      width: 500px;
      margin-top: 40px;
    }
  }
}

@media (max-width: 1400px) {
  .contact__top {
    flex-direction: column;
    h1 {
      margin-top: 40px;
      text-align: center;
    }
    &__left,
    &__right {
      margin: 0;
    }
  }
}

@media (max-width: 900px) {
  .contact__top {
    h1 {
      font-size: 30px;
    }
    img {
      width: 350px;
    }
  }
}

@media (max-width: 650px) {
  .contact__top {
    &__left iframe {
      width: 100%;
    }
    &__address,
    &__phone,
    & legend {
      margin-left: 2px;
    }
  }
}

@media (max-width: 400px) {
  .contact__top {
    img {
      width: 99%;
    }
  }
}
