@import "../../../styles/colors";

.services {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(0, 0, 0, 0.048);
  margin-top: 5px;
  height: auto;
  padding-bottom: 50px;

  &__img {
    width: 400px;
    margin-top: 60px;
    margin-left: 120px;
    margin-right: 60px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-family: inter-bold;
    color: $title-color;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 50px;
    margin-right: 70px;
  }

  &__cards {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 10px;
  }
}

@media (max-width: 1400px) {
  .services {
    &__title {
      margin-right: 0;
    }
    &__info {
      margin: 0;
      width: 100%;
    }
    &__img {
      display: none;
    }
    &__cards {
      width: 100%;
      justify-content: center;
    }
  }
}

@media (max-width: 900px) {
  .services__title {
    font-size: 30px;
  }
}

@media (max-width: 750px) {
  .services__cards {
    grid-template-columns: 48% 48%;
    gap: 3px;
  }
}
@media (max-width: 420px) {
  .services__cards {
    grid-template-columns: 98%;
  }
}
