@import "../../../../styles/colors";

.assessments__card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 5px;
  padding-top: 15px;
  box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.103);
  border-radius: 10px;
  position: relative;
  transition: 200ms all;
  height: 210px;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.16),
      0 3px 4px -1px rgba(0, 0, 0, 0.1);
  }
  &__img {
    width: 50px;
    border-radius: 30px;
    margin-left: 10px;
  }
  &__stars {
    margin-top: 7px;
    margin-right: 50px;
  }
  &__star {
    width: 18px;
  }
  &__text {
    font-family: os-light;
    font-size: 15px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__name {
    font-family: cocogoose;
    font-size: 12px;
    text-align: right;
    position: absolute;
    bottom: 15px;
    right: 20px;
  }
}
