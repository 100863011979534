@import "./../../../styles/colors";

.owl-nav {
  width: 100%;
  font-size: 80px;
  font-family: os-light;
  display: flex;
  justify-content: center;
  color: $dark-gray;
}

.owl-prev:hover,
.owl-next:hover {
  color: $red-hover !important;
}

.owl-prev {
  margin-right: 20px;
  transition: 400ms all;
}

.owl-next {
  margin-left: 20px;
  transition: 400ms all;
}
