@import "../../../styles/colors";

.blog-services-card-link {
  text-decoration: none;
  color: black;
}

.blog-services-card {
  height: 320px;
  width: 300px;
  cursor: pointer;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.24);
}

.blog-services-card-top {
  height: 275px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px 30px 0 0;
}

.blog-services-card-bottom {
  height: 45px;
  border-radius: 0 0 30px 30px;
  transition: 0.3s all;
  font-family: cocogoose;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-gray;
}

/*  RIGHT CARDS */

.blog-services-right-card-link {
  text-decoration: none;
  color: black;
  height: auto;
}

.blog-product-page-card {
  height: 160px;
  width: 200px;
  cursor: pointer;
  margin-bottom: 20px;
}

.blog-product-page-card:last-child {
  margin: 0;
}

.blog-product-page-card-top {
  height: 120px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 0 0;
}

.blog-product-page-card-bottom {
  height: 40px;
  transition: 0.3s all;
  font-family: cocogoose;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  background-color: $light-gray;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

@media (max-width: 1200px) {
  .blog-services-card {
    height: 300px;
    width: 250px;
  }
  .blog-services-card-top {
    height: 260px;
  }
  .blog-services-card-bottom {
    height: 40px;
  }
}

@media (max-width: 600px) {
  .blog-services-card-link {
    width: 100%;
    height: 350px;
  }
  .blog-services-card {
    margin-top: 20px;
    width: 100%;
    height: 350px;
  }
  .blog-services-card-top {
    height: 310px;
  }
  .blog-services-card-bottom {
    height: 40px;
  }
}

@media (max-width: 470px) {
  .blog-services-right-card-link {
    width: 100%;
    height: 350px;
  }
  .blog-product-page-card {
    width: 100%;
    height: 200px;
  }
  .blog-product-page-card-bottom {
    height: 40px;
  }
  .blog-product-page-card-top {
    height: 160px;
  }
}
