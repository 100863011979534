@import "../../../styles/colors";
@import "../../../styles/variables";

.assessments {
  display: flex;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: space-around;
  border-bottom: 1px solid rgba(0, 0, 0, 0.048);
  height: auto;
  h1 {
    font-family: inter-bold;
    color: $title-color;
    font-size: 40px;
    margin-bottom: 50px;
    padding-left: 200px;
    margin-top: 40px;
  }
  .refresh {
    opacity: 0.3;
    cursor: pointer;
    margin-left: 15px;
    margin-bottom: -5px;
  }
  &__img {
    width: 580px;
    margin-left: 80px;
  }
  &__info {
    margin-right: 70px;
  }
  &__cards {
    max-width: 620px;
    max-height: 483.6px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 300px 300px;
  }
  &__button {
    @include button($red, white, 30px);
    padding: 10px 20px;
    width: 130px;
    margin-top: 15px;
    float: right;
    &:hover {
      background-color: $red-hover;
      transform: scale(1.05);
    }
    a {
      text-decoration: none;
      color: white;
    }
  }
}

@media (max-width: 1580px) {
  .assessments__img {
    margin-left: 100px;
    transition: 1s all;
  }
}

@media (max-width: 1400px) {
  .assessments {
    flex-direction: column;
    align-items: center;
    &__img {
      margin: 0;
    }
    h1 {
      padding: 0;
    }
    &__info {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__cards {
      margin: 0;
    }
  }
}

@media (max-width: 900px) {
  .assessments {
    padding-top: 30px;
    &__img {
      width: 380px;
    }
    h1 {
      font-size: 30px;
    }
  }
}

@media (max-width: 650px) {
  .assessments {
    &__cards {
      grid-template-columns: 300px;
      max-height: unset;
      gap: 7px;
    }
    &__button {
      margin: 10px 0 0 0;
    }
  }
}

@media (max-width: 400px) {
  .assessments__img {
    width: 99%;
  }
}